@font-face {
	font-family: Montserrat;
	src: url(Montserrat/static/Montserrat-Regular.ttf);
}
@font-face {
	font-family: Montserrat;
	src: url(Montserrat/static/Montserrat-Bold.ttf);
	font-weight: bold;
}
body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
a {
	color: white;
}
.App {
	text-align: center;
	flex-direction: column;
	display: flex;
	font-family: Montserrat;
	height: 100%;
	background-color: #f4f5f8;
	color: #222428;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #15616d;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: space-between;
	font-size: calc(10px + 2vmin);
	color: white;
}
nav {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: calc(10px + 1vmin);
}
nav ul {
	display: flex;
	flex-direction: row;
}
nav li {
	font-size: smaller;
	display: flex;
	flex-direction: row;
	padding: 10px;
}
.App-Footer {
	background-color: #d04a3f;
	color: white;
	display: flex;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: auto;
}
.App-content {
	justify-content: flex-start;
	background-color: #f4f5f8;
	display: flex;
	flex-direction: column;
	min-height: 60vh;
}

.App-link {
	color: white;
}
