.eclub-form-wrapper {
	width: 100%;
}
.eclub-form {
	display: grid;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-bottom: 50px;
}
.eclub-form-intro {
	flex-wrap: wrap;
	justify-content: left;
	text-align: left;
	font-weight: bold;
	color: #15616d;
	margin: 20px;
	grid-column: 1/4;
	grid-row: 1;
}
.eclub-form-success-message {
	font-size: x-large;
	font-weight: bold;
	color: #15616d;
	padding: 50px;
}
.eclub-form-error-message {
	font-size: larger;
	font-weight: bold;
	color: red;
	padding-top: 20px;
}

.eclub-form-label-name {
	padding-bottom: 20px;
	grid-column: 1;
	grid-row: 2;
}
.eclub-form-name-input-first {
	grid-column: 2;
	grid-row: 2;
	padding: 10px;
	border: 0;
	box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
}
.eclub-form-name-input-last {
	grid-column: 3;
	grid-row: 2;
	padding: 10px;
	border: 0;
	box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
}

.eclub-form-label-email {
	padding-bottom: 20px;
	grid-column: 1;
	grid-row: 4;
}
.eclub-form-email-input {
	padding: 10px;
	border: 0;
	box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	grid-column: 2/4;
	grid-row: 4;
}
.eclub-form-label-company {
	padding-bottom: 20px;
	grid-column: 1;
	grid-row: 6;
}
.eclub-form-company-input {
	padding: 10px;
	border: 0;
	box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	grid-column: 2/4;
	grid-row: 6;
}
.eclub-form-label-comment {
	padding-bottom: 20px;
	grid-column: 1;
	grid-row: 8/9;
}
.eclub-form-comment-input {
	padding: 10px;
	border: 0;
	box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	height: 50px;
	grid-column: 2/4;
	grid-row: 8/9;
}

.eclub-form-submit-button {
	padding: 10px;
	justify-content: center;
	grid-column: 2/4;
	grid-row: 10;
	border-radius: 5px;
	background-color: #15616d;
	color: white;
}

.required-field {
	color: red;
}
.error {
	color: red;
}
.eclub-form-email-error {
	display: grid;
	grid-row: 5;
	grid-column: 2/4;
}
.eclub-form-fname-error {
	display: grid;
	grid-row: 3;
	grid-column: 2;
}
.eclub-form-lname-error {
	display: grid;
	grid-row: 3;
	grid-column: 3;
}
.eclub-form-company-error {
	display: grid;
	grid-row: 7;
	grid-column: 2/4;
}
